/* eslint-disable @typescript-eslint/no-var-requires */

import { ElMessage, ElNotification, ElPageHeader } from "element-plus";
import { Options, Vue } from "vue-property-decorator";
import { ButtonHTMLAttributes, h } from 'vue'
import './index.scss'
import { mapGetters } from "vuex";
import { UserInfoRow } from "@/store/modules/user";
import { ListOpions } from "@/models/type";
import api from "@/api";
import { arrayBufferToBase64, debounceClick } from "@/utils";
import { ElFormItemContext } from "element-plus/lib/el-form";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { getToken } from "@/utils/auth";
const QRCode = require('qrcodejs2')


@Options({
  computed: {
    ...mapGetters(['GetUserInfo'])
  }
})
export default class Vip extends Vue {
  public GetUserInfo?: UserInfoRow // 用户信息
  public memberLevelList: Array<ListOpions> = [] // 会员等级列表
  public loading = false
  public type = ''

  public vipForm: VipParams = {
    cardId: '',
    username: '',
    totalFee: ''
  }

  public payType = 0

  public payList: any[] = [
    {title: '微信支付', value: 2},
    {title: '支付宝支付', value: 1},
    {title: 'PayMe支付', value: 5},
    {title: 'VISA支付', value: 3},
    {title: 'Master支付', value: 4},
  ]
  public payLoading = false
  public show = false
  public timer: any = {}
  public payImg = ''
  public return_id = 0
  public stripe: any = {}
  public elements: any = {}
  public items: any = {}
  public qrCodeUrl = ''

  public formRules: any = {
    username: [
      { required: true, message: '请输入您的真实姓名' }
    ],
    cardId: [
      {required: true, message: '请输入您的身份证号'}
    ]
  }

  /**
   * @description 页头
   * @author Jinx
   * @date 2021-11-04 10:58:28
   * @returns {*}  {JSX.Element}
   * @memberof Vip
   */
  vipHeader(): JSX.Element {
    return <div
      class="vip-header"
    >
      {h(ElPageHeader, {
        title: '空间会员',
        onBack: this.goBack
      })}
      <div class="right pointer" onClick={this.integralShow}>
        <span>积分权益</span>
        <i class="el-icon-arrow-right"/>
      </div>
    </div>
  }

  /**
   * @description 积分权益
   * @author Jinx
   * @date 2021-11-05 10:02:38
   * @memberof Vip
   */
  integralShow(): void {
    this.$router.push({
      name: 'VipIntegral'
    })
  }

  vipContent(): JSX.Element {
    return <div
      class="vip-content"
    >
      <div class="vip-content-title">当前会员：{this.memberLevelName}</div>
      <div class="vip-content-time">会员时间：永久</div>
      <div class="vip-content-label">会员特征</div>
      <el-row>
        <el-col xs={24} sm={12} md={12} lg={8} xl={6}>
          <div class="vip-content-list"><i class="el-icon-check"/><span>参加会员专享活动</span></div>
        </el-col>
        <el-col xs={24} sm={12} md={12} lg={8} xl={6}>
          <div class="vip-content-list"><i class="el-icon-check"/><span>以优惠价格报名参加课程</span></div>
        </el-col>
        <el-col xs={24} sm={12} md={12} lg={8} xl={6}>
          <div class="vip-content-list"><i class="el-icon-check"/><span>购买商品享有折扣优惠</span></div>
        </el-col>
      </el-row>
      <div class="vip-content-bottom">团队会员申请请联系：taosseract@gmail.com</div>
    </div>
  }

  /**
     * @description 根据地址生成二维码
     * @author Jinx
     * @date 2021-10-29 10:34:54
     * @param {string} url
     */
   qrCode(url: string): void {
    const qrcode = new QRCode(this.qrCodeUrl, {
      width: 300, //图像宽度
      height: 300, //图像高度
      colorDark: "#000000", //前景色
      colorLight: "#ffffff", //背景色
      typeNumber: 4,
      correctLevel: QRCode.CorrectLevel.H
      //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
    })
    qrcode.clear()
    qrcode.makeCode(url)
  }

  back(): void {
    this.type = ''
  }

  /**
   * @description 支付页面
   * @author Jinx
   * @date 2021-11-04 14:26:42
   * @returns {*}  {JSX.Element}
   * @memberof Vip
   */
  payDom(): JSX.Element {
    return <div
      class="vip"
    >
      <div class="vip-head" onClick={this.back}>
        <div>
          <i class="el-icon-arrow-left"/>
          <span>返回</span>
        </div>
      </div>
      <div class="vip-sku">
        <div class="vip-sku-list">
          <div>1年期</div>
          <div>HK$<span>100.00</span></div>
        </div>
      </div>
      <div class="vip-title">
        支付方式
      </div>
      <div class="vip-pay">
        {this.payList.map((item, index) => <div
          class={[
            'pay-list',
            this.payType === item.value ? 'pay-list-active' : 'pay-list-primary'
          ]}
          onClick={() => this.choosePay(item.value)}
        >
          <div class={[`pay-list-img_${index}`]} />
          <div>{item.title}</div>
        </div>)}
      </div>
      <div class="vip-bottom">
        <div>实付款：</div>
        <div>HK$</div>
        <div>100.00</div>
      </div>
      <div class="vip-btn">
        {
          this.payType
            ? <div class="pointer" onClick={this.pay}>
                {this.payLoading && <i class="el-icon-loading" style="margin-right:10px"/>}
                <span>确认付款</span>
              </div>
            : <div class="noDrop" title="请选择支付方式">确认付款</div>
        }
      </div>
      {this.show && this.dialogDom()}
    </div>
  }

  dialogClose(): void {
    clearInterval(this.timer as NodeJS.Timeout)
    this.timer = {}
    this.show = false
    this.payImg = ''
  }

  dialogDom(): JSX.Element {
    return <el-dialog
      title=""
      v-model={ this.show }
      width="400px"
      before-close={ this.dialogClose }
      close-on-click-modal={false}
      custom-class="vip-dialog"
    >
      {[3, 4, 5].includes(this.payType as number) && <form id="payment-form">
        <div id="payment-element">
        </div>
        <div id="submit" onClick={this.handleSubmit}>
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">立即支付</span>
        </div>
        <div id="payment-message" class="hidden"></div>
      </form>}
      <div ref="qrCodeUrl"></div>
      <img src={this.payImg} alt="" />
    </el-dialog>
  }

  async handleSubmit(e: Event): Promise<any> {
    e.preventDefault()
    this.setLoading(true)
    const return_url = window.location.href + '?return_id=' + this.return_id + '&payType=' + this.payType
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url
      },
    })
    if (error.type === "card_error" || error.type === "validation_error") {
      this.showMessage(error.message)
    } else {
      this.showMessage('一个意料之外的问题发生了')
    }
    this.setLoading(false)
  }

  // ------- 用户界面助手 -------

  showMessage(messageText: string | any): void {
    const messageContainer = document.querySelector("#payment-message") as Element

    messageContainer.classList.remove("hidden")
    messageContainer.textContent = messageText

    setTimeout(function () {
      messageContainer.classList.add("hidden")
      messageText.textContent = ""
    }, 4000)
  }

  // 在付款提交时显示微调器
  setLoading(isLoading: boolean) {
    if (isLoading) {
      // 禁用按钮并显示微调器
      const submit = document.querySelector("#submit") as ButtonHTMLAttributes
      submit.disabled = true
      document.querySelector("#spinner")?.classList.remove("hidden")
      document.querySelector("#button-text")?.classList.add("hidden")
    } else {
      const submit = document.querySelector("#submit") as ButtonHTMLAttributes
      submit.disabled = false
      document.querySelector("#spinner")?.classList.add("hidden")
      document.querySelector("#button-text")?.classList.remove("hidden")
    }
  }

  async pay(): Promise<void> {
    const obj = Object.assign({}, this.vipForm)
    const { data } = await api.getStripeKey()
    obj.totalFee = '100'
    obj.id = this.GetUserInfo?.id
    switch (this.payType) {
      case 1:
        // 支付宝支付
        if(document.body.clientWidth <= 900) {
          axios({
            url: '/mall-portal/payment/alipay',
            method: 'post',
            headers: {
              Authorization: getToken()
            },
            params:obj
          }).then(res => {
            const oDiv = document.createElement('divform')
            oDiv.innerHTML = res.data
            document.body.appendChild(oDiv)
            document.forms[0].acceptCharset='GBK';
            document.forms[0].submit();
          })
        } else {
          api.paymentPay({
            price: obj.totalFee,
            goodsId: obj.id,
            goodsName: '成为会员',
            subject: '成为会员',
            body: 'member',
            quantity: 1
          }).then(res => {
            this.show = true
            this.$nextTick(() => this.qrCode(res.data))
            this.timer = setInterval(() => {
              api.wxPayPayStatus({outTradeNo: `member-${obj.id}`}).then(res => {
                if(res.data === 1) {
                  ElMessage({type: 'error', message: '支付失败'})
                  clearInterval(this.timer as NodeJS.Timeout)
                  this.timer = {}
                  this.dialogClose()
                } else if(!res.data) {
                  this.show = false
                  clearInterval(this.timer as NodeJS.Timeout)
                  this.timer = {}
                  ElNotification({
                    type: 'success',
                    title: '成功',
                    message: '支付成功'
                  })
                  setTimeout(() => {
                    this.$router.push({
                      name: 'MineSubject'
                    })
                  }, 1000)
                }
              })
            },5000)
          })
        }
        break
      case 2:
        // 微信支付
        if(document.body.clientWidth <= 900) {
          api.wx_pay_other({
            id: obj.id,
            totalFee: obj.totalFee,
            type: 'member',
            url: 'https://www.taosseract.com/mine/subject'
            // url: window.location.href
          }).then(res => {
            window.location.href = res.data
            // window.location.href = 'https://www.taosseract.com/mine/order'
          })
        } else {
          api.wxPayPayUrlMember(obj).then(({ data }) => {
            const img = arrayBufferToBase64(data)
            this.payImg = img
            this.show = true
            clearInterval(this.timer as NodeJS.Timeout)
            this.timer = setInterval(() => {
              api.wxPayPayStatus({outTradeNo: `member-${obj.id}`}).then(res => {
                if(res.data === 1) {
                  ElMessage({type: 'error', message: '支付失败'})
                  clearInterval(this.timer as NodeJS.Timeout)
                  this.timer = {}
                  this.dialogClose()
                } else if(!res.data) {
                  this.show = false
                  clearInterval(this.timer as NodeJS.Timeout)
                  this.timer = {}
                  ElNotification({
                    type: 'success',
                    title: '成功',
                    message: '支付成功'
                  })
                  setTimeout(() => {
                    this.$router.push({
                      name: 'MineActivity'
                    })
                  }, 1000)
                }
              })
            },5000)
          })
        }
        break
      case 3:
      case 4:
      case 5:
        this.stripe = await loadStripe(data)
        this.items.email = '526771102@qq.com'
        this.items.orderId = obj.id
        this.items.orderSn = '' + obj.id
        this.items.payType = '测试',
        this.items.price = obj.totalFee as string,
        this.items.title = '测试'
        this.items.orderType = 'member'
        this.return_id = obj.id as number
        this.initialize()
        break
      default:
    }
  }

  // 获取付款意图并捕获客户端机密
  async initialize() {
    try {
      this.payLoading = true
      const { data } = await api.paymentRender(this.items)
      this.payLoading = false
      this.show = true
      this.$nextTick(() => {
        const {clientSecret} = JSON.parse(data)
        const appearance = {
          theme: 'stripe',
        }
        this.elements = this.stripe.elements({ appearance, clientSecret }) as any

        const paymentElement = this.elements.create("payment")
        paymentElement.mount("#payment-element")
      })
    } catch (error) {
      if(error) {
        this.payLoading = false
        ElNotification({
          type: 'error',
          message: '订单生成失败，请稍后再试'
        })
        return
      }
    }
  }


  choosePay(value: number): void {
    this.payType = value
  }

  vipChangeContent(): JSX.Element {
    return this.type === 'pay'
      ? this.payDom()
      : <div class="vipChangeContent">
        <el-row gutter={50}>
          <el-col xs={24} sm={24} md={12} lg={16} xl={16}>
            <div class="vipChangeContent-left">
              <div class="vipChangeContent-left-top">加入道驿空间会员，享受更优质的服务</div>
              <div class="vipChangeContent-left-intrduce">教育是一家拥有完整体系的在线教育培训机构，且非常注重同学们在这里学习教育是一家拥有完整体系的在线教育培训机构，且非常注重同学们在这里学习</div>
              <div class="vipChangeContent-left-label">会员特权</div>
              <div class="vipChangeContent-left-list"><i class="el-icon-check"/><span>参加会员专享活动</span></div>
              <div class="vipChangeContent-left-list"><i class="el-icon-check"/><span>以优惠价格报名参加课程</span></div>
              <div class="vipChangeContent-left-list"><i class="el-icon-check"/><span>购买商品享有折扣优惠</span></div>
            </div>
          </el-col>
          <el-col xs={24} sm={24} md={12} lg={8} xl={8}>
            <div class="vip-main-right">
              <div class="right-title">申请信息</div>
              <el-form
                ref="form"
                class="mine_site-basic"
                model={this.vipForm}
                rules={this.formRules}
                label-width="100px"
                label-position="top"
              >
                <el-form-item label="姓名" prop="username">
                  <el-input v-model={this.vipForm.username} clearable placeholder="请输入您的真实姓名" />
                </el-form-item>
                <el-form-item label="身份证" prop="cardId">
                  <el-input v-model={this.vipForm.cardId} clearable placeholder="请输入您的身份证号" />
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                  <span>{this.GetUserInfo?.phone || ''}</span>
                </el-form-item>
                <el-form-item label="电邮" prop="email">
                  <span>{this.GetUserInfo?.email}</span>
                </el-form-item>
              </el-form>
              <div class="right-bottom">
                <div>团队会员申请请联系:<br/> taosseract@gmail.com</div>
                <div onClick={() => debounceClick(this.signIn)}>
                  {this.loading && <i class="el-icon-loading"/>}
                  <span style="margin-left: 4px">立即报名</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
    </div>
  }

  signIn(): void {
    const __form = this.$refs.form as ElFormItemContext
    __form.validate(v => {
      if(v) {
        this.type = 'pay'
      } else {
        return false
      }
    })
  }

  getData(): void {
    Promise.all([
      api.memberLevelList()
    ]).then(res => {
      this.memberLevelList = res[0].data.map((item: any) => ({name: item.name, value: item.id}))
    })
  }

  get memberLevelName(): string | undefined {
    const memberLevelId = this.GetUserInfo?.memberLevelId
    return this.memberLevelList.find(item => item.value === memberLevelId)?.name
  }

  /**
   * @description 返回事件
   * @author Jinx
   * @date 2021-11-04 10:58:42
   * @memberof Vip
   */
  goBack(): void {
    this.$router.push({
      name: 'MineSubject'
    })
  }

  created(): void {
    this.getData()
    if(this.$route.query?.redirect_status) {
      const id = this.$route.query.return_id as string
      const payType = this.$route.query.payType as string
      switch (this.$route.query.redirect_status) {
        case "succeeded":
          api.memberCallback({
            id,
            payMoney: '100',
            payType
          }).then(() => {
            ElNotification({
              type: 'success',
              message: '支付成功'
            })
            setTimeout(() => {
              this.$router.push({
                name: 'MineSite'
              })
            }, 1000)
          })
          return
        case "processing":
          ElMessage({
            type: 'error',
            message: '您的付款正在处理中'
          })
          return
        case "requires_payment_method":
          ElMessage({
            type: 'error',
            message: '您的付款未成功，请重试'
          })
          return
        default:
          ElMessage({
            type: 'error',
            message: '出了些问题'
          })
          return
      }
    }
  }

  render(): JSX.Element {
    return <div class="vip">
      {this.vipHeader()}
      {this.memberLevelName === '普通用户' ? this.vipChangeContent() : this.vipContent()}
    </div>
  }
}

export interface VipParams {
  cardId: string
  totalFee: string
  username: string
  id?: number
}
